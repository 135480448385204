import { css } from "@emotion/react";

export const backdrop = css`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(18, 25, 29, 0.64);
  padding-block-start: 9.1875rem;
  padding-inline: var(--spacing-6);
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const modal = css`
  flex-basis: 39.875rem;
  background-color: var(--color-pure-white);
  border-radius: var(--border-radius-2);
  padding-block: var(--spacing-6);
  padding-inline: var(--spacing-10);

  @media screen and (min-width: 43.75rem) {
    padding-block: var(--spacing-12);
    padding-inline: var(--spacing-16);
  }
`;

export const header = css`
  margin-block-end: var(--spacing-12);
`;

export const title = css`
  font-family: var(--font-palanquin);
  font-size: var(--font-size-24);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-36);
`;

export const footer = css`
  margin-block-start: var(--spacing-10);
  display: flex;
  column-gap: var(--grid-gutter-desktop);

  button {
    flex-basis: 50%;
  }
`;
