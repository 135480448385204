import React from 'react';

import * as styles from './PaginationWrapper.styles';

interface Props
{
  children?: React.ReactNode | React.ReactNode[];
}

export const PaginationWrapper = ({children}: Props) => {
  return (
    <section css={styles.wrapper}>{children}</section>
  )
}
