import React from "react";

import * as styles from "../styles/ConfirmationModal.styles";

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  options: {
    opportunityId: number;
    handleFunction: (arg: { opportunityId: number }) => void | null;
  };
}

const ConfirmationModal: React.FC<Props> = ({
  isOpen = false,
  setIsOpen,
  options: { opportunityId, handleFunction },
}) => {
  if (!isOpen) {
    return null;
  }

  // if (document && isOpen) {
  //   document.body.style.overflow = "hidden";
  // } else {
  //   document.body.style.overflow = "unset";
  // }

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div css={styles.backdrop} role="presentation" onClick={closeModal}>
      <article css={styles.modal}>
        <header css={styles.header}>
          <h2 css={styles.title}>Change Opportunity Status</h2>
        </header>
        <section>
          <p>
            Are you sure you want to change opportunity status? By changing the
            opportunity status, you are affecting its visibility and you can’t
            revert it.
          </p>
        </section>
        <footer css={styles.footer}>
          <button
            className="btn btn--primary"
            onClick={() => handleFunction({ opportunityId: opportunityId })}>
            Change Status
          </button>
          <button className="btn btn--ghost" onClick={closeModal}>
            Cancel
          </button>
        </footer>
      </article>
    </div>
  );
};

export default ConfirmationModal;
