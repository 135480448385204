export const getCorrectDate = (date: Date | string | undefined | null) => {
    let dateString = null;
    if (typeof(date) === 'string') {
        date = new Date(date);
    }
    if (date instanceof Date && !isNaN(date.getTime())) {
        dateString = (date.getFullYear() + "-" + ("0"+(date.getMonth()+1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2))
    }
    return dateString;
}
