import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getManagedOpportunities } from "../http";
import { getJoinedOpportunities } from "../http/getJoinedOpportunities";
import { opportunitiesActions } from "../redux";

export const useGetJoinedOpportunities = () => {
  const dispatch = useDispatch();

  const getJoinedOpportunitiesFn = () => {
    dispatch(opportunitiesActions.loading);

    getJoinedOpportunities()
      .then(({ joinedOpportunities }) =>
        dispatch(
          opportunitiesActions.setJoinedOpportunities(joinedOpportunities)
        )
      )
      .catch((error) => {
        dispatch(opportunitiesActions.error(error));
      });
  };

  useEffect(getJoinedOpportunitiesFn, []);
};
