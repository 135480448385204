import React, { useState } from "react";
import * as styles from "../styles/ContactUsModal.styles";

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  opportunityTitle: string | null;
}

const ContactUsModal: React.FC<Props> = ({
  isOpen = false,
  setIsOpen,
  opportunityTitle,
}) => {
  if (!isOpen) {
    return null;
  }

  const [message, setMessage] = useState("");

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div css={styles.backdrop} role="presentation" onClick={closeModal}>
      <article css={styles.modal} onClick={(e) => e.stopPropagation()}>
        <header css={styles.header}>
          <h2 css={styles.title}>{opportunityTitle} - Contact</h2>
          <p>
            Hey! If you have any questions about the opportunity, feel free to
            reach out and we’ll get back to you as soon as possible.
          </p>
        </header>
        <section>
          <div className="input__group" role="presentation">
            <label className="input__label" htmlFor="message">
              Your message
            </label>
            <textarea
              css={styles.textarea}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows={10}
              className="input__field"
              id="message"
              placeholder="Write your message here"></textarea>
          </div>
        </section>
        <footer css={styles.footer}>
          <a
            href={`mailto:robot@volonteros.com?subject=${opportunityTitle}&body=${message}`}
            className={`btn btn--primary btn--block ${
              !message && "btn--disabled"
            }`}>
            Send message
          </a>
        </footer>
      </article>
    </div>
  );
};

export default ContactUsModal;
