import { RouteComponentProps, useParams } from "@reach/router";
import OpportunityLayout from "components/layout/OpportunityLayout/OpportunityLayout";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useGetOpportunity } from "../hooks/useGetOpportunity";

import * as styles from "../styles/OpportunityDetails.styles";
import ApplyForOpportunityModal from "./ApplyForOpportunityModal";

const OpportunityDetails: React.FC<RouteComponentProps> = () => {
  const { opportunityId } = useParams();
  const { getOpportunity, opportunity } = useGetOpportunity();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      getOpportunity(opportunityId);
    }
  }, [isOpen]);

  if (!opportunity) return null;

  return (
    <>
      <OpportunityLayout
        linkLabel="Back to Opportunities"
        linkUrl="/opportunities/all-opportunities"
        customStyles={styles.wrapper}>
        <figure css={styles.figure}>
          <img
            src={opportunity.coverFileUrl as string}
            alt="Opportunity cover"
            css={styles.image}
          />
        </figure>
        <section css={styles.content}>
          <header css={styles.header}>
            <figure css={styles.headerFigure}>
              <img
                css={styles.headerImage}
                src={opportunity.avatarFileUrl as string}
                alt="Opportunity avatar"
              />
            </figure>
            <h1 css={styles.title}>{opportunity.title}</h1>
          </header>
          <p css={styles.text}>{opportunity.longDescription}</p>
        </section>
        <aside css={styles.aside}>
          <dl css={styles.definitionList}>
            <div role="presentation">
              <dt css={styles.term}>Opportunity Date</dt>
              <dd css={styles.definition}>
                <span>
                  <time>
                    {format(
                      new Date(opportunity?.startDate as string),
                      "dd.MM.yyyy."
                    )}
                  </time>{" "}
                  -{" "}
                  <time>
                    {format(
                      new Date(opportunity?.endDate as string),
                      "dd.MM.yyyy."
                    )}
                  </time>
                </span>
              </dd>
            </div>

            <div role="presentation">
              <dt css={styles.term}>Volunteers needed</dt>
              <dd css={styles.definition}>
                <span>
                  <time>
                    {format(
                      new Date(opportunity?.volunteeringStartDate as string),
                      "dd.MM.yyyy."
                    )}
                  </time>{" "}
                  -{" "}
                  <time>
                    {format(
                      new Date(opportunity?.volunteeringEndDate as string),
                      "dd.MM.yyyy."
                    )}
                  </time>
                </span>
              </dd>
            </div>
          </dl>

          <button
            disabled={!opportunity.isOpportunityApplicationVisible}
            onClick={() => setIsOpen(true)}
            className="btn btn--primary btn--block"
            css={styles.applyButton}>
            Apply
          </button>
        </aside>
      </OpportunityLayout>

      <ApplyForOpportunityModal
        selectedOpportunityId={opportunity.id}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
};

export default OpportunityDetails;
