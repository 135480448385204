import { css } from "@emotion/react";

export const wrapper = css`
  row-gap: var(--spacing-12);
  margin-block-start: var(--spacing-10);
`;

export const pageWrapper = css`
  @media screen and (min-width: 48rem) {
    grid-column-start: 7;
    grid-row: 2;
  }
`;

export const imagePreview = css`
  margin-block-start: 0;
`;

export const statusButton = css`
  background-color: transparent;
  display: flex;
  align-items: center;
  column-gap: var(--spacing-3);
  padding: 0;
  border: none;
`;

export const statusIcon = css`
  path {
    stroke: var(--color-tertiary);
  }
`;

export const statusText = css`
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-700);
  line-height: var(--line-height-24);
  color: var(--color-pure-black);
`;

export const opportunityHeader = css`
  grid-column: 1 / -1;
`;

export const opportunityInfo = css`
  display: flex;
  column-gap: calc(var(--spacing-1) * 8.5);

  @media screen and (min-width: 60rem) {
    grid-column: 1 / span 4;
    grid-row: 1;
  }
`;

export const figure = css`
  background-color: var(--color-primary);
  display: flex;
  align-items: center;
  width: 3rem;
  height: 3rem;
  aspect-ratio: 1;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
`;

export const info = css`
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-1);
`;

export const title = css`
  color: var(--color-primary);
  font-family: var(--font-palanquin);
  font-size: var(--font-size-24);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-36);
`;

export const date = css`
  color: var(--color-secondary);
  font-family: var(--font-roboto);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
  line-height: var(---line-height-24);
`;

export const aside = css`
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-6);

  @media screen and (min-width: 60rem) {
    grid-column: 1 / span 4;
    grid-row: 2;
  }
`;

export const tabbedInterface = css`
  @media screen and (min-width: 60rem) {
    grid-column: 7 / -1;
    grid-row: 1;

    .tabs__container {
      padding-inline: 0;
    }
  }
`;

export const content = css`
  @media screen and (min-width: 60rem) {
    grid-column: 7 / -1;
  }
`;

export const table = css`
  width: 100%;
  border-collapse: collapse;
`;

export const tableRow = css`
  border-block-end: 1px solid var(--color-decoration-grey);
`;

export const tableHead = css`
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-21);
  color: var(--color-tertiary);
  text-align: left;
  text-transform: uppercase;
`;

export const tableData = css`
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-24);
  color: var(--color-primary);
  padding-block: var(--spacing-12);
`;
