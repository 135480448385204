import { RouteComponentProps, useParams } from "@reach/router";
import OpportunityLayout from "components/layout/OpportunityLayout/OpportunityLayout";
import TabbedInterface, {
  TabNavigation,
} from "components/TabbedInterface/TabNavigation";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useGetOpportunity } from "../hooks/useGetOpportunity";
import { getOpportunityApplicationsForOpportunity } from "../http/getOpportunityApplicationsForOpportunity";
import OpportunityApplicationModal from "./OpportunityApplicationModal";

import LinkIcon from "assets/icons/Link.svg";
import LogoWhite from "assets/icons/Logo-White.svg";

import * as styles from "../styles/ManageApplications.styles";

const ManageApplications: React.FC<RouteComponentProps> = () => {
  const { opportunityId } = useParams();
  const { getOpportunity, opportunity } = useGetOpportunity();
  const [getSelectedApplicationId, setSelectedApplicationId] = useState<number | null>(null);
  const [opportunityApplications, setOpportunityApplications] = useState<
    OpportunityApplication[] | null
  >(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    getOpportunity(opportunityId);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      getOpportunityApplicationsForOpportunity(opportunityId).then(
        ({ opportunityApplications }) => {
          setOpportunityApplications(opportunityApplications);
        }
      );
    }
  }, [isOpen]);

  const navigationTabs: TabNavigation[] = [
    {
      href: `/opportunities/manage-opportunity/${opportunityId}`,
      label: "Manage Opportunity",
    },
    {
      href: `/opportunities/manage-opportunity/${opportunityId}/manage-applications`,
      label: "Manage Applications",
    },
  ];

  if (!opportunity) return null;

  return (
    <>
      <OpportunityLayout
        linkLabel="Back to opportunities you manage"
        linkUrl="/opportunities/managed-opportunities"
        customStyles={styles.wrapper}>
        <section css={styles.opportunityInfo}>
          <figure css={styles.figure}>
            {opportunity?.avatarFileUrl && (
              <img src={opportunity?.avatarFileUrl} alt="Opportunity avatar" />
            )}
            {!opportunity?.avatarFileUrl && <LogoWhite />}
          </figure>
          <section css={styles.info}>
            <h1 css={styles.title}>{opportunity?.title}</h1>
            <span css={styles.date}>
              <time>
                {format(
                  new Date(opportunity?.startDate as string),
                  "dd.MM.yyyy."
                )}
              </time>{" "}
              -{" "}
              <time>
                {format(
                  new Date(opportunity?.endDate as string),
                  "dd.MM.yyyy."
                )}
              </time>
            </span>
          </section>
        </section>

        <TabbedInterface
          navigationTabs={navigationTabs}
          customStyles={styles.tabbedInterface}
        />

        <section css={styles.content}>
          <table css={styles.table}>
            <thead>
              <tr>
                <th css={styles.tableHead}>Name</th>
                <th css={styles.tableHead}>Status</th>
                <th css={styles.tableHead}>Role</th>
                <th css={styles.tableHead}>Team</th>
                <th colSpan={2}></th>
              </tr>
            </thead>
            <tbody>
              {opportunityApplications?.map((item) => (
                <tr css={styles.tableRow} key={item.id}>
                  <td
                    css={
                      styles.tableData
                    }>{item.fullName}</td>
                  <td css={styles.tableData}>{item.status}</td>
                  <td css={styles.tableData}>{item.assignedTo?.role ?? "-"}</td>
                  <td css={styles.tableData}>
                    {item.assignedTo?.teamName ?? "-"}
                  </td>
                  <td css={styles.tableData}>
                    <LinkIcon style={{cursor: 'pointer'}} onClick={() => { setSelectedApplicationId(item.id); setIsOpen(true); } } />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </OpportunityLayout>

      <OpportunityApplicationModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selectedApplicationId={getSelectedApplicationId}
      />
    </>
  );
};

export default ManageApplications;
