import { getCorrectDate } from "modules/app/utils/getCorrectDate";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { createOpportunityApplication } from "../http/createOpportunityApplication";
import { getOpportunityApplicationUserProfile } from "../http/getOpportunityApplicationUserProfile";

import * as styles from "../styles/ApplyForOpportunityModal.styles";

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedOpportunityId: number | null;
}

const ApplyForOpportunityModal: React.FC<Props> = ({
  isOpen = false,
  setIsOpen,
  selectedOpportunityId,
}) => {
  if (!isOpen) {
    return null;
  }

  const [applicationProfile, setApplicationProfile] =
    useState<GetOpportunityApplicationUserProfile>();
  const [applicationForm, setApplicationForm] =
    useState<CreateOpportunityApplicationProfile>();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm<CreateOpportunityApplicationProfile>();

  // if (document && isOpen) {
  //   document.body.style.overflow = "hidden";
  // } else {
  //   document.body.style.overflow = "unset";
  // }

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (typeof selectedOpportunityId === "number") {
      getOpportunityApplicationUserProfile(selectedOpportunityId).then(
        ({ applicationProfile }) => {
          setApplicationProfile(applicationProfile);
          setApplicationForm({
            opportunityId: selectedOpportunityId as number,
            dateOfBirth: getCorrectDate(applicationProfile!.dateOfBirth),
            address: applicationProfile!.address,
            phoneNumber: applicationProfile!.phoneNumber,
            tShirtSize: applicationProfile!.tShirtSize,
          });
        }
      );
    }
  }, [selectedOpportunityId]);

  console.log(applicationProfile);

  const onSubmit: SubmitHandler<CreateOpportunityApplicationProfile> = (
    data
  ) => {
    console.log(data);
    createOpportunityApplication(data).then(() => {
      closeModal();
    });
  };

  useEffect(() => reset(applicationForm), [reset, applicationForm]);

  if (!applicationProfile) return null;

  return (
    <div css={styles.backdrop} role="presentation" onClick={closeModal}>
      <article css={styles.modal} onClick={(e) => e.stopPropagation()}>
        <header css={styles.header}>
          <h2 css={styles.title}>{applicationProfile.opportunityTitle}</h2>
        </header>
        <section>
          <form
            css={styles.form}
            onSubmit={handleSubmit(onSubmit)}
            id="applyForm">
            <div className="input__group" role="presentation">
              <label className="input__label" htmlFor="email">
                Email Address
              </label>
              <input
                readOnly
                className="input__field"
                type="email"
                id="email"
                value={applicationProfile.email}
              />
            </div>

            <div className="input__group" role="presentation">
              <label className="input__label" htmlFor="dateOfBirth">
                Date of Birth
              </label>
              <input
                className="input__field"
                type="date"
                id="dateOfBirth"
                {...register("dateOfBirth", { required: true })}
              />
            </div>

            <div className="input__group" role="presentation">
              <label className="input__label" htmlFor="address">
                Address
              </label>
              <input
                className="input__field"
                type="text"
                id="address"
                {...register("address", { required: true, minLength: 1 })}
              />
            </div>

            <div className="input__group" role="presentation">
              <label className="input__label" htmlFor="phoneNumber">
                Phone Number
              </label>
              <input
                className="input__field"
                type="text"
                id="phoneNumber"
                {...register("phoneNumber", { required: true, minLength: 1 })}
              />
            </div>

            <div className="input__group" role="presentation">
              <label className="input__label" htmlFor="tShirtSize">
                T-shirt Size
              </label>
              <input
                className="input__field"
                type="text"
                id="tShirtSize"
                {...register("tShirtSize", { required: true, minLength: 1 })}
              />
            </div>
          </form>
        </section>
        <footer css={styles.footer}>
          <button
            type="submit"
            className="btn btn--primary btn--block"
            form="applyForm">
            Apply for the Opportunity
          </button>
        </footer>
      </article>
    </div>
  );
};

export default ApplyForOpportunityModal;
