import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "authConfig";
import { acquireAccessToken } from "modules/app/utils/acquireAccessToken";
import { getManagedOpportunities } from "./getManagedOpportunities";

const msalInstance = new PublicClientApplication(msalConfig);

export const updateOpportunity = async (payload: UpdateOpportunity) => {
  const accessToken = await acquireAccessToken(msalInstance);

  const data = {
    ...payload,
  };

  await fetch(`${process.env.BASE_API_URL}/Opportunity/UpdateOpportunity`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  console.log(payload);

  getManagedOpportunities();
};
