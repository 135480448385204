import { SerializedStyles } from "@emotion/react";
import { Link } from "gatsby";
import React from "react";

import "./TabNavigation.scss";

export interface TabNavigation {
  href: string;
  label: string;
}

interface Props {
  navigationTabs: TabNavigation[];
  customStyles?: SerializedStyles;
}

const TabNavigation: React.FC<Props> = ({ navigationTabs, customStyles }) => {
  return (
    <nav className="tabs" css={customStyles}>
      <div className="tabs__container container">
        <ul className="tabs__list">
          {navigationTabs.map((tab) => (
            <li className="tabs__item" key={tab.label}>
              <Link
                to={tab.href}
                className="tabs__link"
                activeClassName="tabs__link--active">
                {tab.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default TabNavigation;
