import React, { useState } from "react";

import LogoWhite from "assets/icons/Logo-White.svg";
import ArrowRight from "assets/icons/Arrow-Right.svg";

import * as styles from "./JoinedOpportunity.styles";
import { Link } from "gatsby";
import { format } from "date-fns";
import ContactUsModal from "modules/opportunities/components/ContactUsModal";

interface Props {
  joinedOpportunity: JoinedOpportunity;
}

const JoinedOpportunity: React.FC<Props> = ({
  joinedOpportunity: {
    id,
    title,
    startDate,
    endDate,
    shortDescription,
    publicStatus,
    volunteeringStartDate,
    volunteeringEndDate,
    applicationStatus,
    opportunityTeamName,
    opportunityTeamRole,
    avatarFileUrl,
  },
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <article css={styles.wrapper}>
        <header css={styles.header}>
          <div role="presentation" css={styles.headerInfo}>
            <figure css={styles.figure}>
              {avatarFileUrl && (
                <img src={avatarFileUrl} alt="Opportunity avatar" />
              )}
              {!avatarFileUrl && <LogoWhite />}
            </figure>
            <section css={styles.info}>
              <h3 css={styles.title}>{title}</h3>
              <span css={styles.date}>
                <time>{format(new Date(startDate), "dd.MM.yyyy.")}</time> -{" "}
                <time>{format(new Date(endDate), "dd.MM.yyyy.")}</time>
              </span>
            </section>
          </div>
          <div css={styles.pill}>
            <span css={styles.pillText}>{publicStatus}</span>
          </div>
        </header>
        <section css={styles.descriptionWrapper}>
          <dl css={styles.descriptionList}>
            <div css={styles.dlWrapper} role="presentation">
              <dt css={styles.term}>My Status</dt>
              <dd
                css={styles.description}
                data-term="applicationStatus"
                data-description={applicationStatus}>
                {applicationStatus}
              </dd>
            </div>
            <div css={styles.dlWrapper} role="presentation">
              <dt css={styles.term}>My Team</dt>
              <dd css={styles.description}>{opportunityTeamName ?? "-"}</dd>
            </div>
            <div css={styles.dlWrapper} role="presentation">
              <dt css={styles.term}>My Role</dt>
              <dd css={styles.description}>{opportunityTeamRole ?? "-"}</dd>
            </div>
          </dl>
        </section>
        <section css={styles.body}>
          <p>{shortDescription}</p>
        </section>
        <footer css={styles.footer}>
          <dl css={styles.descriptionList}>
            <div css={styles.dlWrapper} role="presentation">
              <dt css={styles.term}>Volunteering Date</dt>
              <dd css={styles.description} data-term="volunteeringDate">
                <span>
                  <time>
                    {format(new Date(volunteeringStartDate), "dd.MM.")}
                  </time>{" "}
                  -{" "}
                  <time>
                    {format(new Date(volunteeringEndDate), "dd.MM.yyyy.")}
                  </time>
                </span>
              </dd>
            </div>
          </dl>

          <button
            css={styles.contactButton}
            className="btn btn--primary"
            onClick={() => setIsOpen(true)}>
            Contact us
          </button>
        </footer>
      </article>

      <ContactUsModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        opportunityTitle={title}
      />
    </>
  );
};

export default JoinedOpportunity;
