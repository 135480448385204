import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "authConfig";
import { acquireAccessToken } from "modules/app/utils/acquireAccessToken";

const msalInstance = new PublicClientApplication(msalConfig);

export const getOpportunity = async (opportunityId: number) => {
  const accessToken = await acquireAccessToken(msalInstance);

  const response = await fetch(
    `${process.env.BASE_API_URL}/Opportunity/GetOpportunity/${opportunityId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  const opportunity: Opportunity = await response.json();

  console.log("getOpportunity: ", opportunity);

  return {
    opportunity,
  };
};
