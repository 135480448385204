import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "authConfig";
import { acquireAccessToken } from "modules/app/utils/acquireAccessToken";

const msalInstance = new PublicClientApplication(msalConfig);

export const getOpportunityApplicationsForOpportunity = async (
  opportunityId: number
) => {
  const accessToken = await acquireAccessToken(msalInstance);

  const response = await fetch(
    `${process.env.BASE_API_URL}/OpportunityApplication/GetOpportunityApplicationsForOpportunity/${opportunityId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  const { opportunityApplications }: GetOpportunityApplicationsForOpportunity =
    await response.json();

  console.log("opportunity applications", opportunityApplications);

  return {
    opportunityApplications,
  };
};
