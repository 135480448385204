import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "authConfig";
import { acquireAccessToken } from "modules/app/utils/acquireAccessToken";

export interface AllOpportunitiesResponse
{
  currentPage: number;
  perPage: number;
  totalCount: number;
  opportunities: AllOpportunity[];
}

export interface QueryParams
{
  CurrentPage?: number;
  PerPage?: number;
  Location?: string;
  StartDateAfter?: string;
  EndDateBefore?: string;
}

const msalInstance = new PublicClientApplication(msalConfig);

export const getAllOpportunities = async ( queryParams?: QueryParams ) =>
{
  let url = `${ process.env.BASE_API_URL }/Opportunity/GetAllOpportunites`;

  let queryString;
  if ( queryParams )
  {
    queryString = Object.entries(queryParams)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join( "&" );

    url = `${process.env.BASE_API_URL}/Opportunity/GetAllOpportunites?${queryString}`;
  }




  const accessToken = await acquireAccessToken(msalInstance);
  const response = await fetch(url
    ,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  const opportunities: AllOpportunitiesResponse = await response.json();

  console.log("getAllOpportunities opportunities: ", opportunities);

  return {
    allOpportunities: opportunities,
  };
};
