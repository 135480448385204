import { RouteComponentProps } from "@reach/router";
import JoinedOpportunity from "components/JoinedOpportunity/JoinedOpportunity";
import { SectionTitleDescription } from "components/SectionTitleDescription/SectionTitleDescription";
import React from "react";
import { useSelector } from "react-redux";
import { useGetJoinedOpportunities } from "../hooks/useGetJoinedOpportunities";
import { opportunitiesSelector } from "../redux";

const JoinedOpportunities: React.FC<RouteComponentProps> = () => {
  useGetJoinedOpportunities();
  const joinedOpportunities = useSelector(
    opportunitiesSelector.selectJoinedOpportunities
  );

  return (
    <>
      <SectionTitleDescription
        title="Opportunities you’ve joined"
        description="See what’s new with the opportunities you’ve joined, there is always enough space for more."
        onPrimary={false}
      />

      {joinedOpportunities?.map((joinedOpportunity) => (
        <JoinedOpportunity
          joinedOpportunity={joinedOpportunity}
          key={joinedOpportunity.id}
        />
      ))}
    </>
  );
};

export default JoinedOpportunities;
