import { AuthenticatedTemplate } from "@azure/msal-react";
import { Redirect, RouteComponentProps, Router, useMatch } from "@reach/router";
import Layout from "components/layout/Layout";
import TabbedInterface, {
  TabNavigation,
} from "components/TabbedInterface/TabNavigation";
import React from "react";
import AllOpportunities from "./components/AllOpportunities";
import CreateOpportunity from "./components/CreateOpportunity";
import JoinedOpportunities from "./components/JoinedOpportunities";
import ManageApplications from "./components/ManageApplications";
import ManagedOpportunities from "./components/ManagedOpportunities";
import ManageOpportunity from "./components/ManageOpportunity";
import OpportunityDetails from "./components/OpportunityDetails";

const OpportunitiesPage: React.FC<RouteComponentProps> = () => {
  const matchCreate = useMatch("/opportunities/create-opportunity");
  const matchManage = useMatch("/opportunities/manage-opportunity/*");
  const matchDetails = useMatch("/opportunities/opportunity-details/:opportunityId");
  const navigationTabs: TabNavigation[] = [
    {
      href: "/opportunities/all-opportunities",
      label: "All Opportunities" },
    {
      href: "/opportunities/joined-opportunities",
      label: "Opportunities you've joined",
    },
    {
      href: "/opportunities/managed-opportunities",
      label: "Opportunities you manage",
    },
  ];
  return (
    <Layout>
      <AuthenticatedTemplate>
        <section className="opportunities-content">
          {!matchCreate && !matchManage && !matchDetails && (
            <TabbedInterface navigationTabs={navigationTabs} />
          )}
          <div
            className="opportunity-content__container container"
            role="presentation">
            <Router>
              <Redirect from="/opportunities" to="/opportunities/all-opportunities" noThrow />
              <AllOpportunities path="/opportunities/all-opportunities" />
              <ManagedOpportunities path="/opportunities/managed-opportunities" />
              <JoinedOpportunities path="opportunities/joined-opportunities" />
              <CreateOpportunity path="/opportunities/create-opportunity" />
              <ManageOpportunity path="/opportunities/manage-opportunity/:opportunityId" />
              <ManageApplications path="/opportunities/manage-opportunity/:opportunityId/manage-applications" />
              <OpportunityDetails path="/opportunities/opportunity-details/:opportunityId" />
            </Router>
          </div>
        </section>
      </AuthenticatedTemplate>
    </Layout>
  );
};

export default OpportunitiesPage;
