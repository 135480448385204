import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "authConfig";
import { acquireAccessToken } from "modules/app/utils/acquireAccessToken";

const msalInstance = new PublicClientApplication(msalConfig);

export const getOpportunityApplication = async (
  opportunityApplicationId: number
) => {
  const accessToken = await acquireAccessToken(msalInstance);

  const response = await fetch(
    `${process.env.BASE_API_URL}/OpportunityApplication/GetOpportunityApplication/${opportunityApplicationId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  const application: SelectedOpportunityApplication = await response.json();

  console.log(application);

  return {
    application,
  };
};
