import React from "react";

import LogoWhite from "assets/icons/Logo-White.svg";
import ArrowRight from "assets/icons/Arrow-Right.svg";

import * as styles from "./ManagedOpportunity.styles";
import { Link } from "gatsby";
import { format } from "date-fns";

interface Props {
  managedOpportunity: ManagedOpportunity;
}

const ManagedOpportunity: React.FC<Props> = ({
  managedOpportunity: {
    id,
    title,
    startDate,
    endDate,
    status,
    numberOfApplications,
    opportunityManagers,
    avatarFileUrl,
  },
}) => {
  type OpportunityStatus =
    | "AcceptingApplications"
    | "ClosedForApplications"
    | "Draft"
    | "InterviewsAndSelection"
    | "Interviews & Selection"
    | "Closed";

  const convertToTitleCase = (pascalCase: OpportunityStatus) => {
    let param: OpportunityStatus = pascalCase.trim() as OpportunityStatus;

    switch (param) {
      case "InterviewsAndSelection":
        param = "Interviews & Selection";
        return param;
      case "ClosedForApplications":
        param = "Closed";
        return param;
    }

    let result = param.replace(/([A-Z])/g, " $1");
    result = result.charAt(0).toUpperCase() + result.slice(1);

    return result;
  };

  return (
    <article css={styles.wrapper}>
      <header css={styles.header}>
        <figure css={styles.figure}>
          {avatarFileUrl && (
            <img
              src={avatarFileUrl}
              css={styles.image}
              alt="Opportunity avatar"
            />
          )}
          {!avatarFileUrl && <LogoWhite />}
        </figure>
        <section css={styles.info}>
          <h3 css={styles.title}>{title}</h3>
          <span css={styles.date}>
            <time>{format(new Date(startDate), "dd.MM.yyyy.")}</time> -{" "}
            <time>{format(new Date(endDate), "dd.MM.yyyy.")}</time>
          </span>
        </section>
      </header>
      <section css={styles.body}>
        <dl css={styles.descriptionList}>
          <div css={styles.dlWrapper} role="presentation">
            <dt css={styles.term}>Status</dt>
            <dd
              css={styles.description}
              data-term="status"
              data-description={status}>
              {convertToTitleCase(status)}
            </dd>
          </div>
          <div css={styles.dlWrapper} role="presentation">
            <dt css={styles.term}>No. of applications</dt>
            <dd css={styles.description}>{numberOfApplications}</dd>
          </div>
          <div css={styles.dlWrapper} role="presentation">
            <dt css={styles.term}>Opportunity manager</dt>
            <dd css={styles.description}>{opportunityManagers?.join(", ")}</dd>
          </div>
        </dl>
      </section>
      <footer css={styles.footer}>
        <Link css={styles.link} to={`/opportunities/manage-opportunity/${id}`}>
          <ArrowRight />
        </Link>
      </footer>
    </article>
  );
};

export default ManagedOpportunity;
