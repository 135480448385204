import { css } from "@emotion/react";

export const wrapper = css`
  row-gap: 5.5rem;
  margin-block-end: 7.75rem;
`;

export const figure = css`
  grid-column: 1 / -1;
  padding-block: 3.875rem;
`;

export const image = css`
  max-width: 100%;
  width: 100%;
  aspect-ratio: 1296 / 780;
  object-fit: cover;
  object-position: center;
`;

export const content = css`
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-4);

  @media screen and (min-width: 60rem) {
    grid-column: 1 / span 8;
  }
`;

export const header = css`
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-3);
`;

export const headerFigure = css`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  overflow: hidden;
`;

export const headerImage = css`
  max-width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
`;

export const title = css`
  font-family: var(--font-palanquin);
  font-size: var(--font-size-36);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-48);
`;

export const text = css`
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-24);
  color: var(--color-secondary);
`;

export const aside = css`
  @media screen and (min-width: 60rem) {
    grid-column: 10 / span 3;
  }
`;

export const definitionList = css`
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-10);
  margin-block-start: calc(3rem + var(--spacing-3));
`;

export const term = css`
  font-family: var(--font-palanquin);
  font-size: var(--font-size-24);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-36);
`;

export const definition = css`
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-24);
  color: var(--color-secondary);
`;

export const applyButton = css`
  margin-block-start: var(--spacing-10);
`;
