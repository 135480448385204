import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import { getOpportunityApplication } from "../http/getOpportunityApplication";
import { approveOpportunityApplication } from "../http/approveOpportunityApplication";
import { rejectOpportunityApplication } from "../http/rejectOpportunityApplication";
import { setUserToAdministratorRoleForOpportunity } from "../http/setUserToAdministratorRoleForOpportunity";
import { setUserToVolunteerRoleForOpportunity } from "../http/setUserToVolunteerRoleForOpportunity";
import { useParams } from "@reach/router";
import { getOpportunityTeamsForOpportunity } from "../http/getOpportunityTeamsForOpportunity";
import { assignUserAsTeamLeaderToOpportunityTeam } from "../http/assignUserAsTeamLeaderToOpportunityTeam";
import { assignUserAsVolunteerToOpportunityTeam } from "../http/assignUserAsVolunteerToOpportunityTeam";

import * as styles from "../styles/OpportunityApplicationModal.styles";

import RadioEmptyIcon from "assets/icons/Radio-Empty.svg";
import RadioSelectedIcon from "assets/icons/Radio-Selected.svg";
import { getCorrectDate } from "modules/app/utils/getCorrectDate";

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedApplicationId: number | null;
}

const OpportunityApplicationModal: React.FC<Props> = ({
  isOpen = false,
  setIsOpen,
  selectedApplicationId,
}) => {
  if (!isOpen) {
    return null;
  }

  const { opportunityId } = useParams();

  const [application, setApplication] =
    useState<SelectedOpportunityApplication>();
  const [opportunityTeams, setOpportunityTeams] = useState<
    OpportunityTeam[] | null
  >(null);
  const [selectedTeam, setSelectedTeam] = useState<number>(0);

  const closeModal = () => {
    setIsOpen(false);
  };

  const fetchOpportunityApplication = () => {
    if (typeof selectedApplicationId === "number") {
      getOpportunityApplication(selectedApplicationId).then(
        ({ application }) => {
          console.log(application);
          setApplication(application);
          if (application?.assignedTeam) {
            setSelectedTeam(application.assignedTeam.id);
          }
        }
      );
    }
  };

  console.log("application", application);

  useEffect(() => {
    fetchOpportunityApplication();
  }, [selectedApplicationId]);

  useEffect(() => {
    getOpportunityTeamsForOpportunity(opportunityId).then((response) => {
      setOpportunityTeams(response.opportunityTeams.teams);
    });
  }, [opportunityId]);

  const handleSelect = (e: BaseSyntheticEvent) => {
    console.log(e);
    const parsetSelectedValue = parseInt(e.target.value);
    if (!isNaN(parsetSelectedValue)) {
      setSelectedTeam(parsetSelectedValue);
    } else {
      setSelectedTeam(0);
    }
  };

  console.log("opportunityId", typeof opportunityId);

  if (!application) return null;

  return (
    <div css={styles.backdrop} role="presentation" onClick={closeModal}>
      <article css={styles.modal} onClick={(e) => e.stopPropagation()}>
        <header css={styles.header}>
          <h2 css={styles.title}>{application.assignedId}</h2>
        </header>
        <section>
          <dl css={styles.definitionList}>
            <div css={styles.definitionListItem} role="presentation">
              <dt css={styles.term}>Email Address</dt>
              <dd>{application.email}</dd>
            </div>

            <div css={styles.definitionListItem} role="presentation">
              <dt css={styles.term}>Date of Birth</dt>
              <dd>{getCorrectDate(application.dateOfBirth)}</dd>
            </div>

            <div css={styles.definitionListItem} role="presentation">
              <dt css={styles.term}>Address</dt>
              <dd>{application.address}</dd>
            </div>

            <div css={styles.definitionListItem} role="presentation">
              <dt css={styles.term}>Phone Number</dt>
              <dd>{application.phoneNumber}</dd>
            </div>

            <div css={styles.definitionListItem} role="presentation">
              <dt css={styles.term}>T-shirt Size</dt>
              <dd>{application.tShirtSize}</dd>
            </div>
          </dl>

          <hr css={styles.divider} />

          <section css={styles.manageSection}>
            <div>
              <h3 css={styles.manageHeading}>Status</h3>
              <div css={styles.btnWrapper}>
                {/* <button disabled css={styles.statusButton}>
                {application.status === "Pending" ? (
                  <RadioSelectedIcon />
                ) : (
                  <RadioEmptyIcon css={styles.statusIcon} />
                )}
                <span css={styles.statusText}>Pending</span>
              </button> */}
                <button
                  disabled={!application.canChangeStatus}
                  css={styles.statusButton}
                  onClick={() =>
                    approveOpportunityApplication({
                      opportunityApplicationId: application.id,
                    }).then(() => fetchOpportunityApplication())
                  }>
                  {application.status === "Approved" ? (
                    <RadioSelectedIcon />
                  ) : (
                    <RadioEmptyIcon css={styles.statusIcon} />
                  )}
                  <span css={styles.statusText}>Approved</span>
                </button>

                <button
                  disabled={!application.canChangeStatus}
                  css={styles.statusButton}
                  onClick={() =>
                    rejectOpportunityApplication({
                      opportunityApplicationId: application.id,
                    }).then(() => fetchOpportunityApplication())
                  }>
                  {application.status === "Rejected" ? (
                    <RadioSelectedIcon />
                  ) : (
                    <RadioEmptyIcon css={styles.statusIcon} />
                  )}
                  <span css={styles.statusText}>Rejected</span>
                </button>
              </div>
            </div>

            <div>
              <h3 css={styles.manageHeading}>Opportunity Role</h3>
              <div css={styles.btnWrapper}>
                <button
                  disabled={!application.canAssignTeamAndRole}
                  css={styles.statusButton}
                  onClick={() =>
                    setUserToAdministratorRoleForOpportunity({
                      opportunityId: parseInt(opportunityId),
                      userProfileId: application.userProfileId,
                    })
                  }>
                  {application?.opportunityRole === "Owner"
                  || application?.opportunityRole === "Administrator" ? (
                    <RadioSelectedIcon />
                  ) : (
                    <RadioEmptyIcon css={styles.statusIcon} />
                  )}
                  <span css={styles.statusText}>Administrator</span>
                </button>

                <button
                  disabled={!application.canAssignTeamAndRole}
                  css={styles.statusButton}
                  onClick={() =>
                    setUserToVolunteerRoleForOpportunity({
                      opportunityId: parseInt(opportunityId),
                      userProfileId: application.userProfileId,
                    })
                  }>
                  {application?.opportunityRole === "Volunteer" ? (
                    <RadioSelectedIcon />
                  ) : (
                    <RadioEmptyIcon css={styles.statusIcon} />
                  )}
                  <span css={styles.statusText}>Volunteer</span>
                </button>
              </div>
            </div>

            <div className="input__group" role="presentation">
              <label htmlFor="opportunityTeam" css={styles.manageHeading}>
                Team
              </label>
              <select
                disabled={!application.canAssignTeamAndRole}
                className="input__field"
                name="opportunityTeam"
                id="opportunityTeam"
                onInput={handleSelect}
                value={selectedTeam}>
                <option value={undefined}>Select</option>
                {opportunityTeams &&
                  opportunityTeams.map((team) => (
                    <option value={team.id} key={team.id}>
                      {team.name}
                    </option>
                  ))}
              </select>
            </div>

            <div>
              <h3 css={styles.manageHeading}>Team Role</h3>
              <div css={styles.btnWrapper}>
                <button
                  disabled={!application.canAssignTeamAndRole || selectedTeam === 0}
                  css={styles.statusButton}
                  onClick={() =>
                    assignUserAsTeamLeaderToOpportunityTeam({
                      opportunityTeamId: selectedTeam
                        ? selectedTeam
                        : opportunityTeams![0].id,
                      userProfileId: application.userProfileId,
                    }).then(() => fetchOpportunityApplication())
                  }>
                  {application?.assignedTeam?.id === selectedTeam
                  && application?.assignedTeam?.role === "TeamLeader" ? (
                    <RadioSelectedIcon />
                  ) : (
                    <RadioEmptyIcon css={styles.statusIcon} />
                  )}
                  <span css={styles.statusText}>Team Leader</span>
                </button>

                <button
                  disabled={!application.canAssignTeamAndRole || selectedTeam === 0}
                  css={styles.statusButton}
                  onClick={() =>
                    assignUserAsVolunteerToOpportunityTeam({
                      opportunityTeamId: selectedTeam
                        ? selectedTeam
                        : opportunityTeams![0].id,
                      userProfileId: application.userProfileId,
                    }).then(() => fetchOpportunityApplication())
                  }>
                  {application?.assignedTeam?.id === selectedTeam
                  && application?.assignedTeam?.role === "Volunteer" ? (
                    <RadioSelectedIcon />
                  ) : (
                    <RadioEmptyIcon css={styles.statusIcon} />
                  )}
                  <span css={styles.statusText}>Volunteer</span>
                </button>
              </div>
            </div>
          </section>
        </section>
        {/* <footer css={styles.footer}>
          <button
            type="submit"
            className="btn btn--primary btn--block"
            form="applyForm">
            Save Changes
          </button>
        </footer> */}
      </article>
    </div>
  );
};

export default OpportunityApplicationModal;
