import { acquireAccessToken } from "./../utils/acquireAccessToken";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

export const fileUpload = async (payload: FormData) => {
  const accessToken = await acquireAccessToken(msalInstance);

  const response = await fetch(`${process.env.BASE_API_URL}/FileUpload`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: payload,
  });

  const { fileName, fileUrl } = await response.json();

  return { fileName, fileUrl } as FileUploadResponse;
};
