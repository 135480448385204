import { RouteComponentProps, useParams } from "@reach/router";
import OpportunityLayout from "components/layout/OpportunityLayout/OpportunityLayout";
import { fileUpload } from "modules/app/http/fileUpload";
import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { navigate } from "gatsby";

import ImageIcon from "assets/icons/Image.svg";
import RadioEmptyIcon from "assets/icons/Radio-Empty.svg";
import RadioSelectedIcon from "assets/icons/Radio-Selected.svg";
import LogoWhite from "assets/icons/Logo-White.svg";

import * as styles from "../styles/CreateOpportunity.styles";
import * as manageStyles from "../styles/ManageOpportunity.styles";
import { Button } from "components/Button";
import { useGetOpportunity } from "../hooks/useGetOpportunity";
import { updateOpportunity } from "../http/updateOpportunity";
import { format } from "date-fns";
import { setOpportunityToAcceptingApplicationsStatus } from "../http/setOpportunityToAcceptingApplicationsStatus";
import { setOpportunityToInterviewsAndSelectionStatus } from "../http/setOpportunityToInterviewsAndSelectionStatus";
import { setOpportunityToClosedForApplicationsStatus } from "../http/setOpportunityToClosedForApplicationsStatus";
import TabbedInterface, {
  TabNavigation,
} from "components/TabbedInterface/TabNavigation";
import ConfirmationModal from "./ConfirmationModal";
import { getCorrectDate } from "modules/app/utils/getCorrectDate";

const ManageOpportunity: React.FC<RouteComponentProps> = () => {
  const { opportunityId } = useParams();
  const { getOpportunity, opportunity } = useGetOpportunity();
  const [endDate, setEndDate] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOptions, setModalOptions] = useState<{
    opportunityId: number;
    handleFunction: (arg: { opportunityId: number }) => void | null;
  }>({ opportunityId: opportunityId, handleFunction: () => null });

  console.log(opportunity);

  const [avatarImage, setAvatarImage] = useState<FileUploadResponse>({
    fileName: "",
    fileUrl: "",
  });
  const [coverImage, setCoverImage] = useState<FileUploadResponse>({
    fileName: "",
    fileUrl: "",
  });
  const [teams, setTeams] = useState<CreateOpportunityOpportunityTeam[]>([
    { name: "General" },
  ]);
  const { register, handleSubmit, setValue, reset } =
    useForm<UpdateOpportunity>();

  const onSubmit: SubmitHandler<UpdateOpportunity> = (data) => {
    updateOpportunity(data);
    navigate("/opportunities/managed-opportunities");
  };

  const handleAvatarUpload = async (e: BaseSyntheticEvent) => {
    let formData = new FormData();
    formData.append("formFile", e.target.files[0]);

    const response = await fileUpload(formData);

    setAvatarImage(response);

    setValue("avatarFileName", response.fileName);
  };

  const handleCoverUpload = async (e: BaseSyntheticEvent) => {
    let formData = new FormData();
    formData.append("formFile", e.target.files[0]);

    const response = await fileUpload(formData);

    setCoverImage(response);

    setValue("coverFileName", response.fileName);
  };

  const handleAddTeam = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setTeams((prev) => [...prev, { name: "" }]);
  };

  const handleTeam = (index: number, e: BaseSyntheticEvent) => {
    e.preventDefault();

    const { value } = e.target;
    const list = [...teams];

    list[index] = { name: value };
    setTeams(list);
  };

  useEffect(() => {
    setValue("teams", teams);
  }, [handleTeam]);

  useEffect(() => {
    getOpportunity(opportunityId);
  }, []);

  useEffect(() => {
    reset({
      ...opportunity,
      startDate: getCorrectDate(opportunity?.startDate),
      endDate: getCorrectDate(opportunity?.endDate),
      volunteeringStartDate: getCorrectDate(opportunity?.volunteeringStartDate),
      volunteeringEndDate: getCorrectDate(opportunity?.volunteeringEndDate),
    })
  }, [reset, opportunity]);

  useEffect(() => {
    if (opportunity?.endDate) {
      setEndDate(
        format(Date.parse(opportunity.endDate as string), "yyyy-mm-dd")
      );
    }
    console.log(endDate);
  }, [opportunity, endDate]);

  const navigationTabs: TabNavigation[] = [
    {
      href: `/opportunities/manage-opportunity/${opportunityId}`,
      label: "Manage Opportunity",
    },
    {
      href: `/opportunities/manage-opportunity/${opportunityId}/manage-applications`,
      label: "Manage Applications",
    },
  ];

  const handleOpenModal = (callback: any) => {
    setIsModalOpen(true);

    setModalOptions({
      opportunityId: opportunityId,
      handleFunction: callback,
    });
  };

  if (!opportunity) return null;

  return (
    <>
      <OpportunityLayout
        linkLabel="Back to opportunities you manage"
        linkUrl="/opportunities/managed-opportunities"
        customStyles={manageStyles.wrapper}>
        <section css={manageStyles.opportunityInfo}>
          <figure css={manageStyles.figure}>
            {opportunity?.avatarFileUrl && (
              <img src={opportunity?.avatarFileUrl} alt="Opportunity avatar" />
            )}
            {!opportunity?.avatarFileUrl && <LogoWhite />}
          </figure>
          <section css={manageStyles.info}>
            <h1 css={manageStyles.title}>{opportunity?.title}</h1>
            <span css={manageStyles.date}>
              <time>
                {format(
                  new Date(opportunity?.startDate as string),
                  "dd.MM.yyyy."
                )}
              </time>{" "}
              -{" "}
              <time>
                {format(
                  new Date(opportunity?.endDate as string),
                  "dd.MM.yyyy."
                )}
              </time>
            </span>
          </section>
        </section>
        <TabbedInterface
          navigationTabs={navigationTabs}
          customStyles={manageStyles.tabbedInterface}
        />
        <aside css={manageStyles.aside}>
          <button
            css={manageStyles.statusButton}
            onClick={() =>
              handleOpenModal(setOpportunityToAcceptingApplicationsStatus)
            }>
            {opportunity.status === "AcceptingApplications" ? (
              <RadioSelectedIcon />
            ) : (
              <RadioEmptyIcon css={manageStyles.statusIcon} />
            )}
            <span css={manageStyles.statusText}>Accepting Applications</span>
          </button>
          <button
            css={manageStyles.statusButton}
            onClick={() =>
              handleOpenModal(setOpportunityToInterviewsAndSelectionStatus)
            }>
            {opportunity.status === "InterviewsAndSelection" ? (
              <RadioSelectedIcon />
            ) : (
              <RadioEmptyIcon css={manageStyles.statusIcon} />
            )}
            <span css={manageStyles.statusText}>Interviews & Selection</span>
          </button>
          <button
            css={manageStyles.statusButton}
            onClick={() =>
              handleOpenModal(setOpportunityToClosedForApplicationsStatus)
            }>
            {opportunity.status === "ClosedForApplications" ? (
              <RadioSelectedIcon />
            ) : (
              <RadioEmptyIcon css={manageStyles.statusIcon} />
            )}
            <span css={manageStyles.statusText}>Closed</span>
          </button>
          <button css={manageStyles.statusButton}>
            {opportunity.status === "Draft" ? (
              <RadioSelectedIcon />
            ) : (
              <RadioEmptyIcon css={manageStyles.statusIcon} />
            )}
            <span css={manageStyles.statusText}>Draft</span>
          </button>
        </aside>
        <article css={[styles.pageWrapper, manageStyles.pageWrapper]}>
          <section>
            <div
              css={[styles.imagePreview, manageStyles.imagePreview]}
              role="presentation">
              <figure css={styles.figure}>
                {opportunity?.avatarFileUrl && (
                  <img
                    css={styles.image}
                    src={opportunity?.avatarFileUrl}
                    alt="Opportunity avatar"
                  />
                )}
                {!opportunity?.avatarFileUrl && <ImageIcon />}
              </figure>
              <div css={styles.infoWrapper} role="presentation">
                <div css={styles.imageInfo} role="presentation">
                  <p css={styles.label}>Opportunity Avatar</p>
                  <p css={styles.restrictions}>
                    .jpg, .jpeg, .png format, 1:1 ratio, up to 50MB
                  </p>
                </div>
                <div role="complementary">
                  <input
                    css={styles.fileInput}
                    type="file"
                    id="opportunityAvatar"
                    className="input__file"
                    onChange={handleAvatarUpload}
                  />
                </div>
              </div>
            </div>

            <form
              onSubmit={handleSubmit(onSubmit)}
              id="create-opportunity-form">
              <input
                type="text"
                id="avatarFileName"
                {...register("avatarFileName")}
                className="visually-hidden"
                aria-hidden="true"
              />
              <input
                type="text"
                id="coverFileName"
                {...register("coverFileName")}
                className="visually-hidden"
                aria-hidden="true"
              />
              <div className="input__group" role="presentation">
                <label className="input__label" htmlFor="title">
                  Title
                </label>
                <input
                  className="input__field"
                  type="text"
                  id="title"
                  placeholder="Opportunity title"
                  {...register("title", { required: true })}
                />
              </div>

              <div css={styles.dates} role="presentation">
                <div className="input__group" role="presentation">
                  <label className="input__label" htmlFor="startDate">
                    Start Date
                  </label>
                  <input
                    className="input__field"
                    type="date"
                    id="startDate"
                    {...register("startDate")}
                  />
                </div>

                <div className="input__group" role="presentation">
                  <label className="input__label" htmlFor="endDate">
                    End Date
                  </label>
                  <input
                    defaultValue={endDate}
                    className="input__field"
                    type="date"
                    id="endDate"
                    {...register("endDate")}
                  />
                </div>
              </div>

              <div className="input__group" role="presentation">
                <label className="input__label" htmlFor="shortDescription">
                  Short Description (250 characters max)
                </label>
                <input
                  className="input__field"
                  type="text"
                  id="shortDescription"
                  placeholder="Make it short and sweet"
                  {...register("shortDescription", {
                    required: true,
                    maxLength: 250,
                  })}
                />
              </div>

              <div className="input__group" role="presentation">
                <label className="input__label" htmlFor="longDescription">
                  Long Description
                </label>
                <textarea
                  css={styles.textarea}
                  rows={10}
                  className="input__field"
                  id="longDescription"
                  placeholder="Describe your opportunity in details"
                  {...register("longDescription", {
                    required: true,
                  })}></textarea>
              </div>

              <div css={styles.imagePreviewLarge} role="presentation">
                <div css={styles.imageInfo} role="presentation">
                  <p css={styles.label}>Opportunity Cover</p>
                  <p css={styles.restrictions}>
                    .jpg, .jpeg, .png format, 16:9 ratio, up to 50MB
                  </p>
                </div>
                <figure css={styles.figureLarge}>
                  {opportunity?.coverFileUrl && (
                    <img
                      css={styles.imageLarge}
                      src={opportunity?.coverFileUrl}
                      alt="Opportunity cover"
                    />
                  )}
                  {!opportunity?.coverFileUrl && <ImageIcon />}
                </figure>
                <div role="complementary">
                  <input
                    css={styles.fileInput}
                    type="file"
                    id="opportunityCover"
                    className="input__file"
                    onChange={handleCoverUpload}
                  />
                </div>
              </div>

              <fieldset css={styles.fieldset}>
                <legend css={styles.label}>Volunteers needed</legend>
                <div css={styles.dates} role="presentation">
                  <div className="input__group" role="presentation">
                    <label
                      className="input__label"
                      htmlFor="volunteeringStartDate">
                      From
                    </label>
                    <input
                      className="input__field"
                      type="date"
                      id="volunteeringStartDate"
                      {...register("volunteeringStartDate")}
                    />
                  </div>

                  <div className="input__group" role="presentation">
                    <label
                      className="input__label"
                      htmlFor="volunteeringEndDate">
                      To
                    </label>
                    <input
                      className="input__field"
                      type="date"
                      id="volunteeringEndDate"
                      {...register("volunteeringEndDate")}
                    />
                  </div>
                </div>
              </fieldset>

              <fieldset css={styles.fieldset}>
                <legend css={styles.label}>Team/Department</legend>
                {opportunity?.teams.map((team, index) => (
                  <div
                    className="input__group"
                    role="presentation"
                    key={`${team.name}-${index}`}>
                    <label className="input__label" htmlFor="teams">
                      Team {index + 1}
                    </label>
                    <input
                      defaultValue={team.name as string}
                      className="input__field"
                      type="text"
                      id="teams"
                      onBlur={(e) => handleTeam(index, e)}
                    />
                  </div>
                ))}
                <Button
                  type="primary"
                  size="sm"
                  onClick={handleAddTeam}
                  css={styles.addButton}>
                  + Add New
                </Button>
              </fieldset>

              <button
                type="submit"
                form="create-opportunity-form"
                className="btn btn--primary btn--block">
                Save Changes
              </button>
            </form>
          </section>
          <footer></footer>
        </article>
      </OpportunityLayout>

      <ConfirmationModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        options={modalOptions}
      />
    </>
  );
};

export default ManageOpportunity;
