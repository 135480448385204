import { SEO } from "components/SEO";
import OpportunitiesPage from "modules/opportunities/OpportunitiesPage";
import React from "react";

const Opportunities: React.FC = () => {
  return (
    <>
      <SEO title="Opportunities" />
      <OpportunitiesPage />
    </>
  );
};

export default Opportunities;
