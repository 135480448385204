import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "authConfig";
import { acquireAccessToken } from "modules/app/utils/acquireAccessToken";
import { getOpportunityApplication } from "./getOpportunityApplication";

const msalInstance = new PublicClientApplication(msalConfig);

export const approveOpportunityApplication = async (
  payload: ApproveOpportunityApplication
) => {
  const accessToken = await acquireAccessToken(msalInstance);

  const data = {
    ...payload,
  };

  await fetch(
    `${process.env.BASE_API_URL}/OpportunityApplication/ApproveOpportunityApplication`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );

  console.log(payload);

  getOpportunityApplication(payload.opportunityApplicationId);
};
