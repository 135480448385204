import { Pagination } from "@mui/material";
import { RouteComponentProps } from "@reach/router";
import { FeaturedOpportunity } from "components/FeaturedOpportunity/FeaturedOpportunity";
import NoResults from "components/NoResults/NoResults";
import { PaginationWrapper } from "components/PaginationWrapper/PaginationWrapper";
import { SectionTitleDescription } from "components/SectionTitleDescription/SectionTitleDescription";
import React, { BaseSyntheticEvent, useCallback, useEffect, useState } from "react";
import { AllOpportunitiesResponse, getAllOpportunities } from "../http/getAllOpportunities";
import ApplyForOpportunityModal from "./ApplyForOpportunityModal";

const AllOpportunities: React.FC<RouteComponentProps> = () => {
  const [ allOpportunities, setAllOpportunities ] = useState<AllOpportunitiesResponse | undefined>();
  const [ paginationPage, setPaginationPage ] = useState( 1 );
  const [ currentPage, setCurrentPage ] = useState( 1 );

  const fetchAllOpportunities = useCallback(() => {
    getAllOpportunities({CurrentPage: currentPage, PerPage: 1}).then(
      ({ allOpportunities }) => {
        setAllOpportunities(allOpportunities);
      }
    )
  }, [currentPage, paginationPage])

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOpportunity, setSelectedOpportunity] = useState<number | null>(
    null
  );

  const handleClick = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    fetchAllOpportunities();
  }, [currentPage, paginationPage]);

  useEffect(() => {
    if (!isOpen && selectedOpportunity) {
      fetchAllOpportunities();
    }
  }, [ isOpen ] );

  const handlePaginationChange = ( event: BaseSyntheticEvent, page: number ) =>
  {
    setPaginationPage(() => page );
    setCurrentPage(() => page );
  };

  return (
    <>
      <SectionTitleDescription
        title="Opportunities"
        description="Check out upcoming opportunities or create one of your own."
        onPrimary={false}
      />

      {allOpportunities?.opportunities.length ? (
        allOpportunities?.opportunities.map((allOpportunity) => (
          <FeaturedOpportunity
            opportunity={allOpportunity}
            actions={allOpportunity.isOpportunityApplicationVisible}
            key={allOpportunity.id}
            handleClick={handleClick}
            setSelectedOpportunity={setSelectedOpportunity}
          />
        ))
      ) : (
        <NoResults />
      ) }

      <PaginationWrapper>
        <Pagination count={ allOpportunities && ( Math.ceil( allOpportunities.totalCount / allOpportunities.perPage ) ) } onChange={ handlePaginationChange } shape='rounded' />
      </PaginationWrapper>

      <ApplyForOpportunityModal
        selectedOpportunityId={selectedOpportunity}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
};

export default AllOpportunities;
