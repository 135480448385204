import { RouteComponentProps } from "@reach/router";
import ManagedOpportunity from "components/ManagedOpportunity/ManagedOpportunity";
import NoResults from "components/NoResults/NoResults";
import { SectionTitleDescription } from "components/SectionTitleDescription/SectionTitleDescription";
import React from "react";
import { useSelector } from "react-redux";
import { useGetManagedOpportunities } from "../hooks/useGetManagedOpportunities";
import { opportunitiesSelector } from "../redux";

const ManagedOpportunities: React.FC<RouteComponentProps> = () => {
  useGetManagedOpportunities();
  const managedOpportunities = useSelector(
    opportunitiesSelector.selectManagedOpportunities
  );

  return (
    <>
      <SectionTitleDescription
        title="Opportunities you manage"
        description="Keep on rockin’ and thanks for being such an amazing member of the community!"
        onPrimary={false}
        isCta={true}
        ctaText="Create Opportunity +"
        ctaUrl="/opportunities/create-opportunity"
      />

      {managedOpportunities.length ? (
        managedOpportunities.map((managedOpportunity) => (
          <ManagedOpportunity
            managedOpportunity={managedOpportunity}
            key={managedOpportunity.id}
          />
        ))
      ) : (
        <NoResults />
      )}
    </>
  );
};

export default ManagedOpportunities;
