import { useState } from "react";
import { getOpportunity } from "../http/getOpportunity";

export const useGetOpportunity = () => {
  const [data, setData] = useState<Opportunity>();

  const getOpportunityFn = (opportunityId: number) => {
    getOpportunity(opportunityId)
      .then(({ opportunity }) => setData(opportunity))
      .catch((error) => {
        console.log(error);
      });
  };

  return {
    getOpportunity: getOpportunityFn,
    opportunity: data,
  };
};
