import { RouteComponentProps } from "@reach/router";
import OpportunityLayout from "components/layout/OpportunityLayout/OpportunityLayout";
import { fileUpload } from "modules/app/http/fileUpload";
import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { saveCreateOpportunity } from "../http/saveCreateOpportunity";
import { navigate } from "gatsby";

import ImageIcon from "assets/icons/Image.svg";

import * as styles from "../styles/CreateOpportunity.styles";
import { Button } from "components/Button";

const CreateOpportunity: React.FC<RouteComponentProps> = () => {
  const [avatarImage, setAvatarImage] = useState<FileUploadResponse>({
    fileName: "",
    fileUrl: "",
  });
  const [coverImage, setCoverImage] = useState<FileUploadResponse>({
    fileName: "",
    fileUrl: "",
  });
  const [teams, setTeams] = useState<CreateOpportunityOpportunityTeam[]>([
    { name: "General" },
  ]);
  const { register, handleSubmit, setValue } = useForm<CreateOpportunity>();

  const onSubmit: SubmitHandler<CreateOpportunity> = (data) => {
    saveCreateOpportunity(data).then((response) => {
      if (response.ok) {
        navigate("/opportunities/managed-opportunities");
      }
    });
  };

  const handleAvatarUpload = async (e: BaseSyntheticEvent) => {
    let formData = new FormData();
    formData.append("formFile", e.target.files[0]);

    const response = await fileUpload(formData);

    setAvatarImage(response);

    setValue("avatarFileName", response.fileName);
  };

  const handleCoverUpload = async (e: BaseSyntheticEvent) => {
    let formData = new FormData();
    formData.append("formFile", e.target.files[0]);

    const response = await fileUpload(formData);

    setCoverImage(response);

    setValue("coverFileName", response.fileName);
  };

  const handleAddTeam = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setTeams((prev) => [...prev, { name: "" }]);
  };

  const handleTeam = (index: number, e: BaseSyntheticEvent) => {
    e.preventDefault();

    const { value } = e.target;
    const list = [...teams];

    list[index] = { name: value };
    setTeams(list);
  };

  useEffect(() => {
    setValue("teams", teams);
  }, [handleTeam]);

  return (
    <OpportunityLayout
      linkLabel="Back"
      linkUrl="/opportunities/managed-opportunities">
      <article css={styles.pageWrapper}>
        <h1 css={styles.title}>Create new opportunity</h1>
        <section>
          <div css={styles.imagePreview} role="presentation">
            <figure css={styles.figure}>
              {avatarImage.fileUrl && (
                <img
                  css={styles.image}
                  src={avatarImage.fileUrl}
                  alt="Opportunity avatar"
                />
              )}
              {!avatarImage.fileUrl && <ImageIcon />}
            </figure>
            <div css={styles.infoWrapper} role="presentation">
              <div css={styles.imageInfo} role="presentation">
                <p css={styles.label}>Opportunity Avatar</p>
                <p css={styles.restrictions}>
                  .jpg, .jpeg, .png format, 1:1 ratio, up to 50MB
                </p>
              </div>
              <div role="complementary">
                <input
                  css={styles.fileInput}
                  type="file"
                  id="opportunityAvatar"
                  className="input__file"
                  onChange={handleAvatarUpload}
                />
              </div>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} id="create-opportunity-form">
            <input
              type="text"
              id="avatarFileName"
              {...register("avatarFileName")}
              className="visually-hidden"
              aria-hidden="true"
            />
            <input
              type="text"
              id="coverFileName"
              {...register("coverFileName")}
              className="visually-hidden"
              aria-hidden="true"
            />

            <div className="input__group" role="presentation">
              <label className="input__label" htmlFor="title">
                Title
              </label>
              <input
                required
                className="input__field"
                type="text"
                id="title"
                placeholder="Opportunity title"
                {...register("title", { required: true })}
              />
            </div>

            <div css={styles.dates} role="presentation">
              <div className="input__group" role="presentation">
                <label className="input__label" htmlFor="startDate">
                  Start Date
                </label>
                <input
                  required
                  className="input__field"
                  type="date"
                  id="startDate"
                  {...register("startDate")}
                />
              </div>
              <div className="input__group" role="presentation">
                <label className="input__label" htmlFor="endDate">
                  End Date
                </label>
                <input
                  required
                  className="input__field"
                  type="date"
                  id="endDate"
                  {...register("endDate")}
                />
              </div>
            </div>

            <div className="input__group" role="presentation">
              <label className="input__label" htmlFor="shortDescription">
                Short Description (250 characters max)
              </label>
              <input
                className="input__field"
                type="text"
                id="shortDescription"
                placeholder="Make it short and sweet"
                {...register("shortDescription", {
                  required: true,
                  maxLength: 250,
                })}
              />
            </div>

            <div className="input__group" role="presentation">
              <label className="input__label" htmlFor="longDescription">
                Long Description
              </label>
              <textarea
                css={styles.textarea}
                rows={10}
                className="input__field"
                id="longDescription"
                placeholder="Describe your opportunity in details"
                {...register("longDescription", { required: true })}></textarea>
            </div>

            <div css={styles.imagePreviewLarge} role="presentation">
              <div css={styles.imageInfo} role="presentation">
                <p css={styles.label}>Opportunity Cover</p>
                <p css={styles.restrictions}>
                  .jpg, .jpeg, .png format, 16:9 ratio, up to 50MB
                </p>
              </div>
              <figure css={styles.figureLarge}>
                {coverImage.fileUrl && (
                  <img
                    css={styles.imageLarge}
                    src={coverImage.fileUrl}
                    alt="Opportunity cover"
                  />
                )}
                {!coverImage.fileUrl && <ImageIcon />}
              </figure>
              <div role="complementary">
                <input
                  css={styles.fileInput}
                  type="file"
                  id="opportunityCover"
                  className="input__file"
                  onChange={handleCoverUpload}
                />
              </div>
            </div>

            <fieldset css={styles.fieldset}>
              <legend css={styles.label}>Volunteers needed</legend>
              <div css={styles.dates} role="presentation">
                <div className="input__group" role="presentation">
                  <label
                    className="input__label"
                    htmlFor="volunteeringStartDate">
                    From
                  </label>
                  <input
                    required
                    className="input__field"
                    type="date"
                    id="volunteeringStartDate"
                    {...register("volunteeringStartDate", { required: true })}
                  />
                </div>
                <div className="input__group" role="presentation">
                  <label className="input__label" htmlFor="volunteeringEndDate">
                    To
                  </label>
                  <input
                    required
                    className="input__field"
                    type="date"
                    id="volunteeringEndDate"
                    {...register("volunteeringEndDate", { required: true })}
                  />
                </div>
              </div>
            </fieldset>

            <fieldset css={styles.fieldset}>
              <legend css={styles.label}>Team/Department</legend>
              {teams.map((team, index) => (
                <div
                  className="input__group"
                  role="presentation"
                  key={`${team.name}-${index}`}>
                  <label className="input__label" htmlFor="teams">
                    Team {index + 1}
                  </label>
                  <input
                    defaultValue={team.name as string}
                    className="input__field"
                    type="text"
                    id="teams"
                    onBlur={(e) => handleTeam(index, e)}
                  />
                </div>
              ))}
              <Button
                type="primary"
                size="sm"
                onClick={handleAddTeam}
                css={styles.addButton}>
                + Add New
              </Button>
            </fieldset>

            <button
              type="submit"
              form="create-opportunity-form"
              className="btn btn--primary btn--block">
              Create Opportunity
            </button>
          </form>
        </section>
        <footer></footer>
      </article>
    </OpportunityLayout>
  );
};

export default CreateOpportunity;
